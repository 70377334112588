import * as React from "react";

import { textSizeClasses } from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "bg-white ring-offset-white flex min-h-[140px]  rounded-lg border border-new-dark-4 bg-new-light-4 p-3 text-new-dark-0 placeholder:text-new-dark-3 focus-visible:border-new-primary-1 focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50",
          textSizeClasses.bodyRegular,
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
