import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { type FC } from "react";
import { LuCalendar } from "react-icons/lu";
import { Calendar } from "src/modules/common/components/atoms/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/modules/common/components/atoms/Popover";
import { Text } from "src/modules/common/components/atoms/Text";
import { Button } from "src/modules/common/components/atoms/buttons/ButtonShadcn";
import { cn } from "~/modules/common/components/utils/styles";
import { type DateInputsize } from "../../molecules/ResponseField";

interface IDateInputProps {
  value?: Date;
  onChange?: (value: string) => void;
  className?: string;
  popoverClassName?: string;
  size?: DateInputsize;
  disabled?: boolean;
}

const DateInput: FC<IDateInputProps> = ({
  value,
  onChange,
  popoverClassName,
  size = "medium",
  disabled = false,
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant={"outline"}
          className={cn(
            "flex w-full items-center justify-between rounded-lg border border-new-dark-4 bg-new-light-4 px-3 text-left font-normal text-new-dark-0",
            !value && "text-muted-foreground",
            size === "medium" && "h-[50px]",
            size === "small" && "h-14",
            size === "xs" && "h-[42px]",
          )}
          disabled={disabled}
        >
          <Text className="text-new-dark-0" size="bodyRegular">
            {value
              ? format(value, "dd/MM/yyyy", { locale: frLocale })
              : "Choisissez une date"}
          </Text>
          <LuCalendar className="h-6 w-6 text-new-dark-3" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className={cn(popoverClassName ? popoverClassName : "w-auto p-0")}
      >
        <Calendar
          mode="single"
          captionLayout="dropdown-buttons"
          defaultMonth={value ?? undefined}
          selected={value ? new Date(value) : undefined}
          onSelect={(date) => onChange && date && onChange(date.toISOString())}
          disabled={(date) => date < new Date()}
          fromYear={new Date().getFullYear()}
          toYear={new Date().getFullYear() + 30}
          onMonthChange={(month) => onChange && onChange(month.toISOString())}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DateInput;
