import { useState, type FC } from "react";
import { Input } from "src/modules/common/components/atoms/inputs/Input";
import { z } from "zod";
import { type ResponseFieldProps } from "./ResponseField";

const PercentageResponseField: FC<ResponseFieldProps> = ({
  placeholder,
  name,
  value,
  onChange,
  className,
  unitClassName,
}) => {
  const parsedNumberValue = z.coerce.number().safeParse(value);
  const initialStringValueInPercent = parsedNumberValue.success
    ? (parsedNumberValue.data * 100).toFixed(2)
    : "";

  const [fieldValue, setFieldValue] = useState<string>(
    initialStringValueInPercent,
  );

  return (
    <Input
      placeholder={placeholder}
      type="text"
      inputMode="numeric"
      name={name}
      key={name}
      value={fieldValue}
      unit="%"
      onBlur={() => {
        if (onChange) {
          const parsedValue = z.coerce.number().safeParse(fieldValue);

          if (parsedValue.success) {
            const valueInDecimal = parsedValue.data / 100;

            onChange(valueInDecimal);
          } else {
            setFieldValue(initialStringValueInPercent);
            onChange("");
          }
        }
      }}
      onChange={(e) => {
        let changedValue = e.target.value;

        if (changedValue.includes(",")) {
          changedValue = changedValue.replace(",", ".");
        }

        setFieldValue(changedValue);
      }}
      className={className}
      unitClassName={unitClassName}
    />
  );
};

export default PercentageResponseField;
