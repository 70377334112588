import { forwardRef, useEffect } from "react";
import { cn } from "~/modules/common/components/utils/styles";
import { textSizeClasses } from "../Text";

export type SelectOption<T = string> = { label: string; value: T };

interface SelectInputProps {
  onChange: (value: string) => void;
  value?: string | number;
  options?: SelectOption<string>[];
  className?: string;
  disabled?: boolean;
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    { options = [], onChange, value = "", className, disabled = false },
    ref,
  ) => {
    // Set first value as default
    useEffect(() => {
      if (value === "" && options[0]) {
        onChange(options[0].value);
      }
    }, [onChange, options, value]);

    return (
      <div
        className={cn(
          "h-[50px] max-h-[72px] w-full rounded-lg border border-new-dark-4 bg-new-light-4 px-3 text-new-dark-0",
          disabled && "cursor-not-allowed bg-new-light-3",
          className,
        )}
      >
        <select
          ref={ref}
          className={cn(
            "h-full w-full border-transparent bg-new-light-4 hover:cursor-pointer focus:outline-none  disabled:cursor-not-allowed disabled:bg-new-light-3 disabled:text-new-dark-3",
            textSizeClasses.bodyRegular,
          )}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={disabled}
        >
          {options?.map((option) => (
            <option key={`${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);

SelectInput.displayName = "SelectInput";
