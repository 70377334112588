import { type AppSvg } from "~/modules/common/interfaces";

const IconRoundedUnCheck: AppSvg = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      {...rest}
    >
      <circle cx="11" cy="11.5" r="10.5" stroke="#DFE0E8" />
    </svg>
  );
};

export default IconRoundedUnCheck;
