export const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Decembre",
];

export const getYearListFromNow = (yearRange: number): number[] => {
  const nowYear = new Date().getFullYear();
  const yearList = [];
  for (let i = nowYear; i <= nowYear + yearRange; i++) {
    yearList.push(i);
  }
  return yearList;
};
