import { useEffect, type FC } from "react";
import { Label } from "../form/label";
import { RadioGroup, RadioGroupItem } from "../RadioGroupShadcn";

export type RadioOption = { label: string; value: string };

interface RadioInputProps {
  onChange: (value: string) => void;
  value?: string;
  options?: RadioOption[];
  className?: string;
  disabled?: boolean;
}

const RadioInput: FC<RadioInputProps> = ({
  options = [],
  onChange,
  value = "",
  className,
  disabled = false,
}) => {
  // Set first value as default
  useEffect(() => {
    if (value === "" && options[0]) {
      onChange(options[0].value);
    }
  }, [onChange, options, value]);

  return (
    <RadioGroup
      value={value}
      onValueChange={(value) => onChange(value)}
      className={className}
      disabled={disabled}
    >
      {options?.map((option) => (
        <div className="flex items-center gap-[6px]" key={option.value}>
          <RadioGroupItem value={option.value} id="option-one" />
          <Label htmlFor={option.value}>{option.label}</Label>
        </div>
      ))}
    </RadioGroup>
  );
};

export default RadioInput;
