import { type FC, type ReactNode, forwardRef } from "react";
import { textSizeClasses } from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";
import IconRoundedCheck from "../icons/IconRoundedCheck";
import IconRoundedUnCheck from "../icons/IconRoundedUnCheck";

export interface InputButtonProps {
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
}

const InputButton: FC<InputButtonProps> = forwardRef<
  HTMLButtonElement,
  InputButtonProps
>(({ children, className, checked, onClick, ...props }, ref) => {
  return (
    <button
      type="button"
      className={cn(
        "flex w-full justify-between rounded-lg border border-new-light-0 px-4 py-5 placeholder:text-new-dark-0 focus-visible:border-new-primary-1 focus-visible:outline-none",
        textSizeClasses.bodyRegular,
        checked && "border-new-primary-1",
        className,
      )}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      <>{children}</>
      <>
        {checked && (
          <div>
            <IconRoundedCheck className=" text-new-primary-1" />
          </div>
        )}
        {!checked && (
          <div>
            <IconRoundedUnCheck className=" text-new-light-0" />
          </div>
        )}
      </>
    </button>
  );
});
InputButton.displayName = "InputButton";

export { InputButton };
