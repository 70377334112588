export const getAddress = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const streetNumber = getStreetNumber(place);
  const streetName =
    getRouteName(place) ?? getColoquialArea(place) ?? getNeighborhood(place);

  if (streetNumber && streetName) {
    return `${streetNumber} ${streetName}`;
  }

  if (streetName) {
    return streetName;
  }

  return null;
};

export const getZipcode = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const zipcode = place.address_components?.find((component) => {
    return component.types.includes("postal_code");
  })?.long_name;

  if (!zipcode) {
    return null;
  }
  return zipcode;
};

export const getCityName = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const cityName =
    place.address_components?.find((component) =>
      component.types.includes("locality"),
    )?.long_name ??
    place.address_components?.find((component) =>
      component.types.includes("postal_town"),
    )?.long_name;

  if (!cityName) {
    return null;
  }

  return cityName;
};

export const getCountry = (place: google.maps.places.PlaceResult) => {
  const country = place.address_components?.find((component) => {
    return component.types.includes("country");
  })?.long_name;

  if (!country) {
    return null;
  }

  return country;
};

export const getRouteName = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const streetName = place.address_components?.find((component) => {
    return component.types.includes("route");
  })?.long_name;

  if (!streetName) {
    return null;
  }

  return streetName;
};

export const getStreetNumber = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const streeNumber = place.address_components?.find((component) => {
    return component.types.includes("street_number");
  })?.long_name;

  if (!streeNumber) {
    return null;
  }

  return streeNumber;
};

export const getColoquialArea = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const coloquialArea = place.address_components?.find((component) => {
    return component.types.includes("colloquial_area");
  });

  if (!coloquialArea) {
    return null;
  }

  return `${coloquialArea.long_name}`;
};

export const getNeighborhood = (
  place: google.maps.places.PlaceResult,
): string | null => {
  const neighborhood = place.address_components?.find((component) => {
    return component.types.includes("neighborhood");
  });

  if (!neighborhood) {
    return null;
  }

  return `${neighborhood.long_name}`;
};
